import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { UserState } from '@/types/UserState';

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      welcomeDialogOpen: true,

      actions: {
        setWelcomeDialogOpen: (welcomeDialogOpen) => set({ welcomeDialogOpen }),
      },
    }),
    {
      name: 'park-ride-hessen/user',
      version: 1,
      partialize: ({ actions, ...rest }) => rest,
    },
  ),
);
