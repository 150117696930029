import { CssBaseline, CssVarsProvider } from '@mui/joy';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRouter } from '@tanstack/react-router';
import React from 'react';
import ReactDOM from 'react-dom/client';

// eslint-disable-next-line no-restricted-imports
import { routeTree } from './routeTree.gen';
import { App } from '@/App';
import { queryClient } from '@/setup/query-client';
import { theme } from '@/setup/theme';
import '@/setup/api';

// Create a new router instance
export const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <App />
      </CssVarsProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
