import { css } from '@emotion/react';
import { IconButton, Sheet, styled } from '@mui/joy';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { getRouteApi } from '@tanstack/react-router';
import { useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';

import { MapGeocoderAutocomplete } from '@/components/map/MapGeocoderAutocomplete';
import { useScreenSize } from '@/hooks/useScreenSize';
import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';
import { Route } from '@/routes';

const StyledSheet = styled(Sheet)(
  ({ theme }) => css`
    position: relative;
    top: ${theme.spacing(3)};
    background: transparent;
    z-index: 1;
    visibility: hidden;
    transform: translateX(0) translateY(calc(-100% - ${theme.spacing(2)}));
    opacity: 0;
    transition:
      transform 300ms,
      opacity 300ms;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      top: ${theme.spacing(2.5)};
      left: 0;
    }
  `,
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    color: ${theme.palette.text.primary};
    box-shadow: 0 3px 6px #00000029;
    border-radius: ${theme.spacing(0.5)};
    padding: ${theme.spacing(1.5)};

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      padding: 0;
    }

    &:hover {
      background-color: ${theme.palette.primary['200']};
    }
  `,
);

const routeApi = getRouteApi('/');

export function SearchBar() {
  const { search } = Route.useSearch();
  const { isDesktop } = useScreenSize();
  const navigate = routeApi.useNavigate();

  const geocoderRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (search) {
      setTimeout(() => geocoderRef.current?.focus(), 300);
    }
  }, [search]);

  return (
    <>
      <Transition in={!search} timeout={300}>
        {(state) => (
          <StyledSheet
            sx={{
              ...(state !== 'exited' && { visibility: 'visible' }),
              ...(['entering', 'entered'].includes(state) && {
                transform: 'translateY(-50%)',
                opacity: 1,
              }),
            }}
          >
            <StyledIconButton
              variant="solid"
              size="lg"
              onClick={() => {
                navigate({
                  search: (prev) => ({
                    ...prev,
                    search: prev.search ? undefined : true,
                  }),
                });
              }}
            >
              <MagnifyingGlass size={isDesktop ? '28px' : '32px'} />
            </StyledIconButton>
          </StyledSheet>
        )}
      </Transition>

      <Transition in={search} timeout={300}>
        {(state) => (
          <MapGeocoderAutocomplete
            ref={geocoderRef}
            sx={{
              ...(state !== 'exited' && { visibility: 'visible' }),
              ...(['entering', 'entered'].includes(state) && {
                transform: 'translateY(-50%)',
                opacity: 1,
              }),
            }}
            onClose={() => navigate({ search: (prev) => ({ ...prev, search: undefined }) })}
          />
        )}
      </Transition>
    </>
  );
}
