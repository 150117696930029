import { css } from '@emotion/react';
import {
  Autocomplete,
  AutocompleteOption,
  Box,
  BoxProps,
  ListItemContent,
  ListItemDecorator,
  styled,
  Typography,
} from '@mui/joy';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { useMap } from '@vis.gl/react-maplibre';
import { forwardRef, useState } from 'react';

import PurIcon from '@/assets/icons/pur-icon.svg?react';
import TrainIcon from '@/assets/icons/train-icon.svg?react';
import { useScreenSize } from '@/hooks/useScreenSize';
import { Geocoder } from '@/modules/Geocoder';
import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';
import { MAP_FLY_TO_ZOOM } from '@/modules/MAP_FLY_TO_ZOOM';
import { PointGuard } from '@/modules/PointGuard';
import { geocodingOptions } from '@/modules/api/photon/@tanstack/react-query.gen';
import { LocationFeature } from '@/types/LocationFeature';
import { MapLayer } from '@/types/MapLayer';
import { SearchResultType } from '@/types/SearchResultType';
import { combinedData, fuse } from '@/utils/fuse';

const Root = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    width: calc(100vw - ${theme.spacing(4)});
    visibility: hidden;
    transform: translateX(0) translateY(calc(-100% + ${theme.spacing(2)}));
    background-color: ${theme.palette.common.white};
    color: ${theme.palette.text.primary};
    box-shadow: 0 3px 6px #00000029;
    left: 0;
    top: ${theme.spacing(3.5)};

    border-radius: ${theme.spacing(0.5)};

    opacity: 0;
    transition:
      transform 300ms,
      opacity 300ms;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      width: ${theme.spacing(40)};
      max-width: calc(100vw - ${theme.spacing(4)});
      left: ${theme.spacing(37)};
      top: ${theme.spacing(2.5)};
    }
  `,
);

const StyledAutocomplete = styled(Autocomplete)(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    height: ${theme.spacing(7)};

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      height: ${theme.spacing(5.5)};
    }

    .MuiAutocomplete-wrapper {
      align-items: stretch;
    }
  `,
) as typeof Autocomplete;

const routeApi = getRouteApi('/');

type MapGeocoderAutocompleteProps = {
  onClose: () => void;
} & Pick<BoxProps, 'sx'>;

export const MapGeocoderAutocomplete = forwardRef<HTMLInputElement, MapGeocoderAutocompleteProps>(
  ({ onClose, ...props }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [newLocation, setNewLocation] = useState<LocationFeature | SearchResultType>();
    const [focus, setFocus] = useState(false);
    const [highlight, setHighlight] = useState<LocationFeature | SearchResultType | null>();
    const { isDesktop } = useScreenSize();
    const { map: current } = useMap();
    const navigate = routeApi.useNavigate();

    const closeAutoComplete = () => {
      navigate({
        search: (prev) => ({
          ...prev,
          search: prev.search === undefined,
        }),
      });
    };

    const { data: queryOptions } = useQuery({
      ...geocodingOptions({ query: { q: inputValue } }),
      enabled: focus && inputValue !== '',
      placeholderData: keepPreviousData,
      select: (data) => data.features.map((feature) => Geocoder.transformToLocationFeature(feature)),
    });

    const optionsIncludeValue =
      newLocation &&
      'properties' in newLocation &&
      queryOptions &&
      queryOptions.find((option) => Geocoder.areSameLocationFeatures(option, newLocation as LocationFeature));
    const autocompleteOptions = [...(queryOptions || [])];
    if (newLocation && !optionsIncludeValue) {
      autocompleteOptions.unshift(newLocation as LocationFeature);
    }

    const getOptionLabelForSearchResultType = (feature: SearchResultType) =>
      feature.properties.pr_name ||
      feature.properties.pr_zufahrtsstr ||
      feature.properties.pr_ort ||
      feature.properties.rmv_name ||
      feature.properties.gml_id ||
      '';

    const getOptionLabelForLocationFeature = (feature: LocationFeature) => feature.properties.name?.firstLine || '';

    const getOptionLabel = (feature: LocationFeature | SearchResultType) => {
      if ('pr_name' in feature.properties || 'rmv_name' in feature.properties) {
        return getOptionLabelForSearchResultType(feature as SearchResultType);
      }

      return getOptionLabelForLocationFeature(feature as LocationFeature);
    };

    const autocompleteOptionsList = [...(queryOptions || [])];

    const searchResults = fuse.search(inputValue).map((result) => result.item);

    const handleChange = (location: LocationFeature | SearchResultType | null) => {
      setFocus(!location);
      setNewLocation(location || undefined);

      if (location) {
        const { coordinates } = location.geometry;

        const match = combinedData.find((item) => item.geometry.coordinates === coordinates);
        if (match && match.properties.gml_id) {
          const layerType = match.properties.mapLayer === MapLayer.PARK_AND_RIDE ? 'PARK_AND_RIDE' : 'TRAIN_STATION';
          navigate({
            search: (search) => ({
              ...search,
              id: match.properties.gml_id,
              menu: true,
              layers: search.layers ? [...new Set([...search.layers, layerType])] : [layerType],
            }),
          });
        } else {
          navigate({
            search: (search) => ({
              ...search,
              id: undefined,
              menu: undefined,
            }),
          });
        }
        setNewLocation(undefined);
      }
    };

    const sortOptions = (options: (LocationFeature | SearchResultType)[]) =>
      options.sort((a, b) => {
        if ('mapLayer' in a && 'mapLayer' in b) {
          if (a.mapLayer === MapLayer.PARK_AND_RIDE && b.mapLayer !== MapLayer.PARK_AND_RIDE) {
            return -1;
          }
          if (a.mapLayer === MapLayer.TRAIN_STATION && b.mapLayer !== MapLayer.TRAIN_STATION) {
            return b.mapLayer === MapLayer.PARK_AND_RIDE ? 1 : -1;
          }
        }

        return 0;
      });

    const sortedAutocompleteOptionsList = sortOptions([...autocompleteOptionsList]);
    const sortedSearchResults = sortOptions([...searchResults]);

    return (
      <Root {...props}>
        <StyledAutocomplete<LocationFeature | SearchResultType>
          size="lg"
          variant="plain"
          startDecorator={<MagnifyingGlass size={isDesktop ? '24px' : '32px'} />}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value) => {
            if ('geometry' in option && 'geometry' in value) {
              return option.geometry.coordinates === value.geometry.coordinates;
            }
            if ('coordinates' in option && 'coordinates' in value) {
              return option.coordinates === value.coordinates;
            }

            return false;
          }}
          options={[...sortedSearchResults, ...sortedAutocompleteOptionsList]}
          autoComplete
          autoFocus
          blurOnSelect
          filterSelectedOptions={!optionsIncludeValue}
          forcePopupIcon={false}
          includeInputInList
          autoHighlight
          value={newLocation || null}
          onChange={(_event, location) => {
            handleChange(location as LocationFeature | null);
            if (PointGuard.hasLongitudeAndLatitude(location?.geometry)) {
              current?.flyTo({
                center: [location.geometry.coordinates[0], location.geometry.coordinates[1]],
                ...(current?.getZoom() < MAP_FLY_TO_ZOOM - 2 && { zoom: MAP_FLY_TO_ZOOM - 2 }),
              });
              setFocus(false);
            }
            closeAutoComplete();
          }}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onHighlightChange={(_event, option) => {
            if (option && 'properties' in option && 'gml_id' in option.properties) {
              setHighlight(option as LocationFeature | SearchResultType);
            }
          }}
          onKeyDown={(event) => {
            if (
              searchResults.length > 0 &&
              event.key === 'Enter' &&
              event.currentTarget.querySelector('input') === document.activeElement
            ) {
              handleChange(searchResults[0] as LocationFeature | SearchResultType);
              event.currentTarget.querySelector('input')?.blur();
            }

            if (event.key === 'Tab') {
              const location = highlight || searchResults[0] || autocompleteOptionsList[0];

              if (location && 'name' in location.properties && inputValue !== location.properties.name?.firstLine) {
                event.preventDefault();
                setInputValue('');
              }
            }
          }}
          renderOption={(renderProps, option) => {
            const icon = (() => {
              if ('properties' in option && 'mapLayer' in option.properties) {
                if (option.properties.mapLayer === MapLayer.PARK_AND_RIDE) {
                  return <PurIcon style={{ marginRight: 8, height: '24px' }} />;
                }
                if (option.properties.mapLayer === MapLayer.TRAIN_STATION) {
                  return <TrainIcon style={{ marginRight: 8, height: '24px' }} />;
                }
              }

              return null;
            })();

            return (
              <AutocompleteOption {...renderProps} key={renderProps.id}>
                <ListItemDecorator>{icon}</ListItemDecorator>
                <ListItemContent>
                  <Typography>{getOptionLabel(option)}</Typography>
                </ListItemContent>
              </AutocompleteOption>
            );
          }}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
            closeAutoComplete();
          }}
          slotProps={{
            input: {
              ref,
            },
          }}
        />
      </Root>
    );
  },
);
