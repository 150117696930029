import { useMap } from '@vis.gl/react-maplibre';
import { StyleImageMetadata } from 'maplibre-gl';
import { useEffect } from 'react';

type UseMapImagesProps = {
  images: {
    name: string;
    url: string;
    width?: number;
    height?: number;
    options?: Partial<StyleImageMetadata>;
  }[];
};

export const useMapImages = ({ images }: UseMapImagesProps) => {
  const { current: map } = useMap();

  useEffect(() => {
    if (map) {
      images.forEach(({ name, url, width, height, options }) => {
        if (!map.hasImage(name)) {
          if (url.split('.').pop() === 'svg' || url.startsWith('data:image/svg+xml;')) {
            const pixelRatio = window.devicePixelRatio;

            const img = new Image(pixelRatio * width!, pixelRatio * height!);
            img.onload = () => !map.hasImage(name) && map.addImage(name, img, { pixelRatio, ...options });
            img.src = url;
          } else {
            (async () => {
              const imageResponse = await map.loadImage(url);

              if (!map.hasImage(name)) {
                map.addImage(name, imageResponse.data, options);
              }
            })();
          }
        }
      });
    }
  }, [images, map]);
};
