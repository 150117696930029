import { useTheme } from '@mui/joy';
import { getRouteApi } from '@tanstack/react-router';
import { Layer, LngLatLike, Source } from '@vis.gl/react-maplibre';
import { Feature, FeatureCollection } from 'geojson';
import maplibregl, { GeoJSONSource, MapLayerEventType } from 'maplibre-gl';
import { useCallback, useMemo } from 'react';

import stationActiveIcon from '@/assets/icons/map-markers/station-active.svg?url';
import stationClusterIcon from '@/assets/icons/map-markers/station-cluster.svg?url';
import purIcon from '@/assets/icons/pur-icon.svg';
import trainIcon from '@/assets/icons/train-icon.svg';
import trainStationData from '@/data/bahnhof_hessen.json';
import purData from '@/data/pundr_hessen.json';
import { useMapImages } from '@/hooks/useMapImages';
import { useMapOnEvent } from '@/hooks/useMapOnEvent';
import { useScreenSize } from '@/hooks/useScreenSize';
import { MAP_FLY_TO_ZOOM } from '@/modules/MAP_FLY_TO_ZOOM';
import { MAP_MARKER_MIN_ZOOM } from '@/modules/MAP_MARKER_MIN_ZOOM';
import { MAP_MAX_ZOOM } from '@/modules/MAP_MAX_ZOOM';
import { Route } from '@/routes';
import { MapLayer } from '@/types/MapLayer';

export const STATIONS_SOURCE = 'stations';
const STATION_CLUSTER_LAYER = `${STATIONS_SOURCE}-cluster-large`;
const STATION_CLUSTER_COUNT_LAYER = `${STATIONS_SOURCE}-cluster-count-layer`;
export const STATIONS_ICON_LAYER = `${STATIONS_SOURCE}-station-icon-layer`;
const STATIONS_ACTIVE_LAYER = `${STATIONS_SOURCE}-station-active-layer`;
const routeApi = getRouteApi('/');

export function StationLayer() {
  const theme = useTheme();
  const { layers, id } = Route.useSearch();
  const navigate = routeApi.useNavigate();
  const { isDesktop } = useScreenSize();

  const data: FeatureCollection = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: [
        ...((layers?.includes('TRAIN_STATION') ? trainStationData.features : []) as Feature[]).map((feature) => ({
          ...feature,
          properties: { ...feature.properties, type: MapLayer.TRAIN_STATION },
        })),
        ...((layers?.includes('PARK_AND_RIDE') ? purData.features : []) as Feature[]).map((feature) => ({
          ...feature,
          properties: { ...feature.properties, type: MapLayer.PARK_AND_RIDE },
        })),
      ],
    }),
    [layers],
  );

  useMapImages({
    images: [
      {
        name: STATION_CLUSTER_LAYER,
        url: stationClusterIcon,
        width: 40,
        height: 40,
      },
      {
        name: purIcon,
        url: purIcon,
        width: 36,
        height: 36,
      },
      {
        name: trainIcon,
        url: trainIcon,
        width: 36,
        height: 36,
      },
      {
        name: STATIONS_ACTIVE_LAYER,
        url: stationActiveIcon,
        width: 40,
        height: 40,
      },
    ],
  });

  const handleMapClick = useCallback(
    ({ features }: MapLayerEventType['click']) => {
      if (features && features[0] && features[0].geometry.type === 'Point' && features[0].properties.gml_id) {
        navigate({ search: (search) => ({ ...search, id: features[0]?.properties.gml_id, menu: true }) });
      }
    },
    [navigate],
  );

  useMapOnEvent({
    type: 'click',
    layer: STATIONS_ICON_LAYER,
    listener: handleMapClick,
  });

  useMapOnEvent({
    type: 'click',
    layer: STATIONS_ICON_LAYER,
    listener: handleMapClick,
  });

  const handleClusterClick = useCallback(
    async ({ target, features }: MapLayerEventType['click']) => {
      const clusterId = features?.[0]?.properties?.cluster_id;

      const leaves = (await (target.getSource(STATIONS_SOURCE) as GeoJSONSource).getClusterLeaves(
        clusterId,
        1000000,
        0,
      )) as any[];

      const bounds = leaves.reduce(
        (currentBounds, feature) => currentBounds.extend(feature.geometry.coordinates as LngLatLike),
        new maplibregl.LngLatBounds(),
      );

      target.fitBounds(bounds, {
        padding: {
          top: isDesktop ? 146 : 128,
          right: isDesktop ? 64 : 32,
          bottom: isDesktop ? 88 : 64,
          left: isDesktop ? 64 : 32,
        },
      });
    },
    [isDesktop],
  );

  useMapOnEvent({
    type: 'click',
    layer: STATION_CLUSTER_LAYER,
    listener: handleClusterClick,
  });

  return (
    <Source
      id={STATIONS_SOURCE}
      type="geojson"
      data={data as FeatureCollection}
      promoteId="id"
      cluster
      clusterRadius={48}
      clusterMaxZoom={MAP_FLY_TO_ZOOM}
      maxzoom={MAP_MAX_ZOOM}
    >
      <Layer
        id={STATION_CLUSTER_LAYER}
        type="symbol"
        filter={['has', 'point_count']}
        layout={{
          'icon-image': STATION_CLUSTER_LAYER,
          'icon-allow-overlap': true,
          'icon-anchor': 'center',
        }}
      />
      <Layer
        id={STATION_CLUSTER_COUNT_LAYER}
        type="symbol"
        filter={['has', 'point_count']}
        layout={{
          'text-allow-overlap': true,
          'text-field': '{point_count_abbreviated}',
          'text-size': 18,
          'text-font': ['Noto Sans Bold'],
        }}
        paint={{
          'text-color': theme.palette.neutral[50],
        }}
      />

      <Layer
        id={STATIONS_ICON_LAYER}
        type="symbol"
        layout={{
          'icon-image': [
            'match',
            ['get', 'type'],
            MapLayer.PARK_AND_RIDE,
            purIcon,
            MapLayer.TRAIN_STATION,
            trainIcon,
            'default-icon',
          ],
          'icon-allow-overlap': true,
        }}
        maxzoom={MAP_MARKER_MIN_ZOOM}
      />

      <Layer
        id={STATIONS_ACTIVE_LAYER}
        beforeId={STATIONS_ICON_LAYER}
        type="symbol"
        filter={['==', ['get', 'gml_id'], id || '']}
        layout={{
          'icon-image': STATIONS_ACTIVE_LAYER,
          'icon-size': 1.125,
          'icon-allow-overlap': true,
          'icon-anchor': 'center',
        }}
      />
    </Source>
  );
}
