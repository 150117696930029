import { Box, Checkbox, ListItem, Modal, ModalClose, ModalDialog, Typography } from '@mui/joy';
import { getRouteApi } from '@tanstack/react-router';

import LayerIcon from '@/assets/icons/layers.svg?react';
import { I18N_DE } from '@/i18n/de';
import { Route } from '@/routes';
import { MapLayer } from '@/types/MapLayer';

type LayersModalProps = {
  onClose: () => void;
  layerDrawerIsOpen: boolean;
};

const routeApi = getRouteApi('/');

export function LayersModal({ onClose, layerDrawerIsOpen }: LayersModalProps) {
  const { layers } = Route.useSearch();
  const navigate = routeApi.useNavigate();

  return (
    <Modal open={layerDrawerIsOpen} onClose={onClose}>
      <ModalDialog layout="fullscreen" sx={{ p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 2, px: 2 }}>
          <Typography startDecorator={<LayerIcon />} level="h3">
            Kartenelemente
          </Typography>
          <ModalClose sx={{ position: 'unset' }} />
        </Box>
        <Box sx={{ overflow: 'auto', px: 2 }}>
          {Object.values(MapLayer).map((component) => (
            <ListItem key={component}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  gap: 2,
                  mb: 1,
                }}
              >
                <Checkbox
                  sx={{ fontWeight: 700 }}
                  color="neutral"
                  size="lg"
                  overlay
                  value={component}
                  checked={!!layers?.includes(component)}
                  label={I18N_DE.MapLayer[component]}
                  onChange={() => {
                    const newLayers = layers?.includes(component)
                      ? layers.filter((layer) => layer !== component)
                      : [...(layers || []), component];
                    navigate({ search: { layers: newLayers } });
                  }}
                />
              </Box>
            </ListItem>
          ))}
        </Box>
      </ModalDialog>
    </Modal>
  );
}
