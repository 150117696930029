import { useMap } from '@vis.gl/react-maplibre';
import { MapLayerEventType } from 'maplibre-gl';
import { useEffect } from 'react';

type UseMapOnEventProps<T extends keyof MapLayerEventType> = {
  type: T;
  layer?: string;
  listener: (event: MapLayerEventType[T] & Object) => void;
  enabled?: boolean;
};

export const useMapOnEvent = <T extends keyof MapLayerEventType>({
  type,
  layer,
  listener,
  enabled = true,
}: UseMapOnEventProps<T>) => {
  const map = useMap();

  useEffect(() => {
    const mapRef = map.current;

    if (enabled) {
      if (layer) {
        mapRef?.on(type, layer, listener);

        return () => {
          mapRef?.off(type, layer, listener);
        };
      }

      mapRef?.on(type, listener);

      return () => {
        mapRef?.off(type, listener);
      };
    }

    return () => {};
  }, [enabled, layer, listener, map, type]);
};
