import { Point } from 'geojson';
import { v4 as uuidv4 } from 'uuid';

import { PhotonFeature } from '@/modules/api/photon';
import { LocationFeature, LocationFeatureProperties } from '@/types/LocationFeature';

export class Geocoder {
  public static areSameLocationFeatures(featureA: LocationFeature, featureB: LocationFeature) {
    return (
      featureA.geometry.coordinates[0] === featureB.geometry.coordinates[0] &&
      featureA.geometry.coordinates[1] === featureB.geometry.coordinates[1]
    );
  }

  private static getNameFromGeocoderFeature({ properties }: PhotonFeature): LocationFeatureProperties['name'] {
    const { osm_key: osmKey, osm_value: osmValue, name, street, housenumber, postcode, city, country } = properties;

    if (osmKey === 'place' && osmValue === 'town' && postcode && name && country) {
      return {
        full: `${postcode} ${name}`,
        firstLine: `${postcode} ${name}`,
        secondLine: country,
      };
    }

    const displayName: string[] = [];

    if (name) {
      displayName.push(name);
    }

    if (street) {
      if (housenumber) {
        displayName.push(`${street} ${housenumber}`);
      } else {
        displayName.push(street);
      }
    }

    if (postcode && city) {
      displayName.push(`${postcode} ${city}`);
    } else {
      if (postcode) {
        displayName.push(postcode);
      }

      if (city) {
        displayName.push(city);
      }
    }

    // TODO: remove = '' ?
    const [firstLine = '', ...secondLine] = displayName;

    return {
      full: displayName.join(', '),
      firstLine,
      secondLine: secondLine.join(', '),
    };
  }

  public static transformToLocationFeature(feature: PhotonFeature): LocationFeature {
    const id = uuidv4();

    return {
      id,
      type: 'Feature',
      geometry: feature.geometry as Point,
      properties: {
        osmKey: feature.properties.osm_key,
        osmId: feature.properties.osm_id,
        name: Geocoder.getNameFromGeocoderFeature(feature),
      },
    };
  }
}
