import { css } from '@emotion/react';
import { Sheet, styled } from '@mui/joy';

import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';
import { transientOptions } from '@/utils/transient-options';

export const MapSidebar = styled(
  Sheet,
  transientOptions,
)<{ $mobileMenuFullscreen: boolean }>(
  ({ theme, $mobileMenuFullscreen }) => css`
    box-shadow: ${theme.shadow.md};
    background-color: ${theme.vars.palette.background.body};
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    transition: height 0.3s ease-in-out;
    overflow: auto;
    height: ${$mobileMenuFullscreen ? '100%' : '40%'};

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      overflow: inherit;
      position: inherit;
      width: ${theme.spacing(58)};
      flex-shrink: 0;
      z-index: 1;
      height: 100%;
      max-height: calc(100vh - ${theme.spacing(9)});
    }
  `,
);
