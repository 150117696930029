import { css } from '@emotion/react';
import { Box, Stack, styled } from '@mui/joy';
import { getRouteApi } from '@tanstack/react-router';

import { SearchBar } from '@/components/SearchBar';
import { MenuBarTop } from '@/components/navigations/MenuBarTop';
import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';
import { MapLayer } from '@/types/MapLayer';

const StyledContainer = styled(Stack)(
  ({ theme }) => css`
    position: fixed;
    z-index: 1;
    gap: ${theme.spacing(2)};
      top: ${theme.spacing(2)};
      left: ${theme.spacing(2)};
  }`,
);

const StyledStack = styled(Stack)(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      display: flex;
      top: ${theme.spacing(2.5)};
      left: ${theme.spacing(2.5)};
    }
  `,
);

const routeApi = getRouteApi('/');

export function MenuBar() {
  const navigate = routeApi.useNavigate();

  return (
    <StyledContainer direction="row">
      <StyledStack direction="row" gap={2}>
        {Object.values(MapLayer).map((mapLayer) => (
          <Box key={mapLayer}>
            <MenuBarTop
              onClick={() =>
                navigate({
                  search: (prev) => ({
                    layers: prev?.layers?.includes(mapLayer)
                      ? prev.layers.filter((layer) => layer !== mapLayer)
                      : [...(prev?.layers || []), mapLayer],
                  }),
                })
              }
              content={mapLayer}
            />
          </Box>
        ))}
      </StyledStack>
      <SearchBar />
    </StyledContainer>
  );
}
