import { css } from '@emotion/react';
import { Box, Stack, styled } from '@mui/joy';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { BaseMap } from '@/components/BaseMap';
import { Footer } from '@/components/Footer';
import { WelcomeModal } from '@/components/modals/WelcomeModal';
import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';
import { MapLayer } from '@/types/MapLayer';

const Root = styled(Stack)(
  () => css`
    width: 100vw;
    height: 100vh;
  `,
);

const FooterWrapper = styled(Box)(
  ({ theme }) => css`
    display: none;
    bottom: 0;
    width: 100%;
    background-color: ${theme.palette.common.white};

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      display: flex;
    }
  `,
);

const mapLayerValues = Object.values(MapLayer) as [string, ...string[]];

const indexSchema = z.object({
  menu: z.boolean().optional(),
  search: z.boolean().optional(),
  id: z.string().optional(),
  layers: z.array(z.enum(mapLayerValues).catch('layers')).optional(),
});

export const Route = createFileRoute('/')({
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  component: Index,
  validateSearch: indexSchema,
});

function Index() {
  return (
    <Root direction="column">
      <WelcomeModal />

      <BaseMap />

      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Root>
  );
}
