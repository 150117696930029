import { css } from '@emotion/react';
import { Box, styled } from '@mui/joy';
import { AttributionControl, LngLatBoundsLike, Map, MapProvider } from '@vis.gl/react-maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';

import Logo from '@/assets/icons/park-ride-hessen.png';
import { StationLayer } from '@/components/map/layers/StationLayer';
import { Menu } from '@/components/map/menu/Menu';
import { MenuBar } from '@/components/navigations/MenuBar';
import { MobileMenuBar } from '@/components/navigations/MobileMenuBar';
import { useScreenSize } from '@/hooks/useScreenSize';
import { MAP_MAX_ZOOM } from '@/modules/MAP_MAX_ZOOM';
import { Route } from '@/routes';

export const BOUNDS = [8.7090022 - 0.1, 50.1141545 - 0.1, 8.7090022 + 0.1, 50.1141545 + 0.1] as LngLatBoundsLike;

const MapWrapper = styled(Box)(
  () => css`
    display: flex;
    width: 100vw;
    height: 100%;
  `,
);

const StyledBox = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(2)};
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
    background-color: rgba(255, 255, 255, 0.75);
    height: ${theme.spacing(11)};
    width: ${theme.spacing(40)};
    ${theme.breakpoints.up('xl')} {
      display: flex;
    }
  `,
);

export function BaseMap() {
  const { isDesktop } = useScreenSize();
  const { menu, id } = Route.useSearch();

  return (
    <Box flex={1}>
      <MapProvider>
        <MenuBar />
        {!isDesktop && <MobileMenuBar />}

        <MapWrapper flex={1}>
          <Map
            id="map"
            dragRotate={false}
            touchPitch={false}
            bearing={0}
            pitch={0}
            mapStyle="https://sgx.geodatenzentrum.de/gdz_basemapde_vektor/styles/bm_web_gry.json"
            initialViewState={{ bounds: BOUNDS }}
            minZoom={5}
            maxZoom={MAP_MAX_ZOOM}
            attributionControl={false}
          >
            <AttributionControl position={isDesktop ? 'top-right' : 'bottom-right'} compact />
            <StationLayer />

            <StyledBox borderRadius="lg">
              <img alt="Park+Ride Hessen" style={{ height: 120 }} src={Logo} />
            </StyledBox>
          </Map>
          {menu && <Menu id={id} />}
        </MapWrapper>
      </MapProvider>
    </Box>
  );
}
