import { styled, Table } from '@mui/joy';
import { css } from '@mui/system';

export const StyledTable = styled(Table)(
  ({ theme }) => css`
    padding: ${theme.spacing(3)} ${theme.spacing(5)};

    .tableRow > table {
      width: calc(100% + 32px);
      padding: ${theme.spacing(2)} 0;
    }

    & tbody > tr > td:first-of-type,
    tbody > tr > td:last-child {
      padding: 0;
      min-height: 24px;
      border: none;
    }

    & tbody > tr > td:nth-of-type(2) {
      text-align: right;
    }

    p {
      white-space: pre-line;
    }
  `,
);
