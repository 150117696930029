import { useTheme } from '@mui/joy';
import { useMediaQuery } from '@mui/system';

import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';

export const useScreenSize = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(MAIN_BREAKPOINT));
  const screenSize: 'mobile' | 'desktop' = isDesktop ? 'desktop' : 'mobile';

  return { screenSize, isMobile: !isDesktop, isDesktop };
};
