/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LegalImport } from './routes/_legal'
import { Route as IndexImport } from './routes/index'

// Create Virtual Routes

const LegalUeberLazyImport = createFileRoute('/_legal/ueber')()
const LegalNutzungsbedingungenLazyImport = createFileRoute(
  '/_legal/nutzungsbedingungen',
)()
const LegalKontaktLazyImport = createFileRoute('/_legal/kontakt')()
const LegalImpressumLazyImport = createFileRoute('/_legal/impressum')()
const LegalFaqLazyImport = createFileRoute('/_legal/faq')()

// Create/Update Routes

const LegalRoute = LegalImport.update({
  id: '/_legal',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const LegalUeberLazyRoute = LegalUeberLazyImport.update({
  id: '/ueber',
  path: '/ueber',
  getParentRoute: () => LegalRoute,
} as any).lazy(() => import('./routes/_legal/ueber.lazy').then((d) => d.Route))

const LegalNutzungsbedingungenLazyRoute =
  LegalNutzungsbedingungenLazyImport.update({
    id: '/nutzungsbedingungen',
    path: '/nutzungsbedingungen',
    getParentRoute: () => LegalRoute,
  } as any).lazy(() =>
    import('./routes/_legal/nutzungsbedingungen.lazy').then((d) => d.Route),
  )

const LegalKontaktLazyRoute = LegalKontaktLazyImport.update({
  id: '/kontakt',
  path: '/kontakt',
  getParentRoute: () => LegalRoute,
} as any).lazy(() =>
  import('./routes/_legal/kontakt.lazy').then((d) => d.Route),
)

const LegalImpressumLazyRoute = LegalImpressumLazyImport.update({
  id: '/impressum',
  path: '/impressum',
  getParentRoute: () => LegalRoute,
} as any).lazy(() =>
  import('./routes/_legal/impressum.lazy').then((d) => d.Route),
)

const LegalFaqLazyRoute = LegalFaqLazyImport.update({
  id: '/faq',
  path: '/faq',
  getParentRoute: () => LegalRoute,
} as any).lazy(() => import('./routes/_legal/faq.lazy').then((d) => d.Route))

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_legal': {
      id: '/_legal'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LegalImport
      parentRoute: typeof rootRoute
    }
    '/_legal/faq': {
      id: '/_legal/faq'
      path: '/faq'
      fullPath: '/faq'
      preLoaderRoute: typeof LegalFaqLazyImport
      parentRoute: typeof LegalImport
    }
    '/_legal/impressum': {
      id: '/_legal/impressum'
      path: '/impressum'
      fullPath: '/impressum'
      preLoaderRoute: typeof LegalImpressumLazyImport
      parentRoute: typeof LegalImport
    }
    '/_legal/kontakt': {
      id: '/_legal/kontakt'
      path: '/kontakt'
      fullPath: '/kontakt'
      preLoaderRoute: typeof LegalKontaktLazyImport
      parentRoute: typeof LegalImport
    }
    '/_legal/nutzungsbedingungen': {
      id: '/_legal/nutzungsbedingungen'
      path: '/nutzungsbedingungen'
      fullPath: '/nutzungsbedingungen'
      preLoaderRoute: typeof LegalNutzungsbedingungenLazyImport
      parentRoute: typeof LegalImport
    }
    '/_legal/ueber': {
      id: '/_legal/ueber'
      path: '/ueber'
      fullPath: '/ueber'
      preLoaderRoute: typeof LegalUeberLazyImport
      parentRoute: typeof LegalImport
    }
  }
}

// Create and export the route tree

interface LegalRouteChildren {
  LegalFaqLazyRoute: typeof LegalFaqLazyRoute
  LegalImpressumLazyRoute: typeof LegalImpressumLazyRoute
  LegalKontaktLazyRoute: typeof LegalKontaktLazyRoute
  LegalNutzungsbedingungenLazyRoute: typeof LegalNutzungsbedingungenLazyRoute
  LegalUeberLazyRoute: typeof LegalUeberLazyRoute
}

const LegalRouteChildren: LegalRouteChildren = {
  LegalFaqLazyRoute: LegalFaqLazyRoute,
  LegalImpressumLazyRoute: LegalImpressumLazyRoute,
  LegalKontaktLazyRoute: LegalKontaktLazyRoute,
  LegalNutzungsbedingungenLazyRoute: LegalNutzungsbedingungenLazyRoute,
  LegalUeberLazyRoute: LegalUeberLazyRoute,
}

const LegalRouteWithChildren = LegalRoute._addFileChildren(LegalRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof LegalRouteWithChildren
  '/faq': typeof LegalFaqLazyRoute
  '/impressum': typeof LegalImpressumLazyRoute
  '/kontakt': typeof LegalKontaktLazyRoute
  '/nutzungsbedingungen': typeof LegalNutzungsbedingungenLazyRoute
  '/ueber': typeof LegalUeberLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof LegalRouteWithChildren
  '/faq': typeof LegalFaqLazyRoute
  '/impressum': typeof LegalImpressumLazyRoute
  '/kontakt': typeof LegalKontaktLazyRoute
  '/nutzungsbedingungen': typeof LegalNutzungsbedingungenLazyRoute
  '/ueber': typeof LegalUeberLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_legal': typeof LegalRouteWithChildren
  '/_legal/faq': typeof LegalFaqLazyRoute
  '/_legal/impressum': typeof LegalImpressumLazyRoute
  '/_legal/kontakt': typeof LegalKontaktLazyRoute
  '/_legal/nutzungsbedingungen': typeof LegalNutzungsbedingungenLazyRoute
  '/_legal/ueber': typeof LegalUeberLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/faq'
    | '/impressum'
    | '/kontakt'
    | '/nutzungsbedingungen'
    | '/ueber'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/faq'
    | '/impressum'
    | '/kontakt'
    | '/nutzungsbedingungen'
    | '/ueber'
  id:
    | '__root__'
    | '/'
    | '/_legal'
    | '/_legal/faq'
    | '/_legal/impressum'
    | '/_legal/kontakt'
    | '/_legal/nutzungsbedingungen'
    | '/_legal/ueber'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  LegalRoute: typeof LegalRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  LegalRoute: LegalRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_legal"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_legal": {
      "filePath": "_legal.tsx",
      "children": [
        "/_legal/faq",
        "/_legal/impressum",
        "/_legal/kontakt",
        "/_legal/nutzungsbedingungen",
        "/_legal/ueber"
      ]
    },
    "/_legal/faq": {
      "filePath": "_legal/faq.lazy.tsx",
      "parent": "/_legal"
    },
    "/_legal/impressum": {
      "filePath": "_legal/impressum.lazy.tsx",
      "parent": "/_legal"
    },
    "/_legal/kontakt": {
      "filePath": "_legal/kontakt.lazy.tsx",
      "parent": "/_legal"
    },
    "/_legal/nutzungsbedingungen": {
      "filePath": "_legal/nutzungsbedingungen.lazy.tsx",
      "parent": "/_legal"
    },
    "/_legal/ueber": {
      "filePath": "_legal/ueber.lazy.tsx",
      "parent": "/_legal"
    }
  }
}
ROUTE_MANIFEST_END */
