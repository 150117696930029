import { css } from '@emotion/react';
import { Box, Button, Modal, ModalDialog, styled, Typography } from '@mui/joy';

import welcomeModal from '@/assets/illustrations/welcomeModal.png?url';
import { LegalLink } from '@/components/legal/LegalLink';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useUserStore } from '@/stores/useUserStore';

const StyledButtonBox = styled(Box)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(4)};
  `,
);

const StyledButton = styled(Button)(
  ({ theme }) => css`
    text-transform: uppercase;
    border-radius: 0;
    width: ${theme.spacing(20)};
    height: ${theme.spacing(7)};
    font-weight: 700;
    font-size: ${theme.vars.fontSize.xl};
  `,
);

export function WelcomeModal() {
  const open = useUserStore((state) => state.welcomeDialogOpen);
  const { setWelcomeDialogOpen } = useUserStore((state) => state.actions);

  const handleClose = () => setWelcomeDialogOpen(false);

  const { isMobile } = useScreenSize();

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog sx={{ borderRadius: 0, padding: 6, overflow: 'auto' }} layout={isMobile ? 'fullscreen' : 'center'}>
        <img
          style={{ width: '100%', height: 'auto', marginBottom: '1.5rem' }}
          src={welcomeModal}
          alt="Herzlich Willkommen zu LKW Lotse"
        />
        <Typography level="h4">
          Herzlich Willkommen auf dem Webportal für LKW-Informationen in der Region Frankfurt RheinMain!
        </Typography>
        <Typography>
          Das Projekt LKW Lotse stellt ein Vorrangnetz für den LKW-Verkehr und LKW-relevante Beschilderungen in der
          Region Frankfurt RheinMain bereit. Die Daten können in dieser Anwendung betrachtet werden. Die Netz- und
          Beschilderungsdaten stehen in der Mobilithek zum Download zur Verfügung.
        </Typography>

        <StyledButtonBox>
          <LegalLink href="/ueber">Mehr erfahren</LegalLink>

          <StyledButton onClick={handleClose}>Los!</StyledButton>
        </StyledButtonBox>
      </ModalDialog>
    </Modal>
  );
}
