import { Divider, Link, Stack, Typography } from '@mui/joy';

import PuRIcon from '@/assets/icons/pur-icon.svg';
import { KeyTypography } from '@/components/map/stations/table/KeyTypography';
import { MeansOfTransportTable } from '@/components/map/stations/table/MeansOfTransportTable';
import { StyledTable } from '@/components/map/stations/table/StyledTable';
import { ValueTypography } from '@/components/map/stations/table/ValueTypography';

type PurTableProps = {
  menuData: {
    icon: string;
    menuData: Record<string, any>;
  };
};

export function PurTable({ menuData: { menuData } }: PurTableProps) {
  return (
    <>
      <Stack display="flex" gap={4} alignItems="center" flexDirection="row" justifyContent="flex-start" px={4} pt={2}>
        <img alt="Park + Ride Icon" src={PuRIcon} />
        <Typography level="body-lg" fontWeight={600}>
          {menuData.pr_name}
        </Typography>
      </Stack>
      <StyledTable>
        <tbody>
          <tr>
            <td>
              <KeyTypography>Park + Ride</KeyTypography>
            </td>
          </tr>
          <tr>
            <td>
              <KeyTypography>Stellplatzzahl:</KeyTypography>
            </td>
          </tr>
          <tr>
            <td>
              <KeyTypography>Stellplatzzahl</KeyTypography>
            </td>
            <td>
              <ValueTypography>{menuData.pr_stellpl}</ValueTypography>
            </td>
          </tr>
          <tr>
            <td>
              <KeyTypography>Behindertenparkplätze</KeyTypography>
            </td>
            <td>
              <ValueTypography>
                {menuData.pr_behinderten_stellpl === '' ? '0' : menuData.pr_behinderten_stellpl}
              </ValueTypography>
            </td>
          </tr>
          <tr>
            <td>
              <KeyTypography>Barrierefreier Zugang ?</KeyTypography>
            </td>
            <td>
              <ValueTypography>
                {menuData.pr_zugang_barrierefrei === '' ? 'Nein' : menuData.pr_zugang_barrierefrei}
              </ValueTypography>
            </td>
          </tr>
          {menuData.pr_auslastung_text && (
            <tr>
              <td colSpan={2}>
                <ValueTypography paddingTop={1}>{menuData.pr_auslastung_text}</ValueTypography>
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>

      {(menuData.pr_einschr_art !== '' || menuData.pr_organisation || menuData.pr_sonstige_merkmale !== '') && (
        <>
          <Divider />
          <StyledTable>
            <tbody>
              {menuData.pr_organisation && (
                <tr>
                  <td>
                    <ValueTypography>{menuData.pr_organisation}</ValueTypography>
                  </td>
                </tr>
              )}
              {menuData.pr_sonstige_merkmale !== '' && (
                <tr>
                  <td>
                    <ValueTypography>{menuData.pr_sonstige_merkmale}</ValueTypography>
                  </td>
                </tr>
              )}
              {menuData.pr_einschr_art && (
                <tr>
                  <td>
                    <ValueTypography>{menuData.pr_einschr_art}</ValueTypography>
                  </td>
                  <td>
                    <ValueTypography>{menuData.pr_einschr_art}</ValueTypography>
                  </td>
                </tr>
              )}
            </tbody>
          </StyledTable>
        </>
      )}

      {menuData.publ_nutzer !== '' && (
        <>
          <Divider />
          <StyledTable>
            <tbody>
              <tr>
                <td>
                  <ValueTypography>{menuData.publ_nutzer}</ValueTypography>
                </td>
              </tr>
            </tbody>
          </StyledTable>
        </>
      )}

      <Divider />

      <StyledTable>
        <tbody>
          <tr>
            <td>
              <KeyTypography>Adresse</KeyTypography>
            </td>
          </tr>
          <tr>
            <td>
              <ValueTypography>{menuData.pr_zufahrtsstr}</ValueTypography>
            </td>
          </tr>
          <tr>
            <td>
              <ValueTypography>
                {menuData.pr_plz} {menuData.pr_ort}
              </ValueTypography>
            </td>
          </tr>
        </tbody>
      </StyledTable>

      <Divider />

      <StyledTable>
        <tbody>
          <tr>
            <td>
              <KeyTypography>Bewirtschaftung</KeyTypography>
            </td>
            <td>
              <ValueTypography textAlign="end">{menuData.pr_bewirtschaftung}</ValueTypography>
            </td>
          </tr>
        </tbody>
      </StyledTable>

      <Divider />

      <StyledTable>
        <tbody>
          <tr>
            <td>
              <KeyTypography>Öffnungszeiten</KeyTypography>
            </td>
            <td>
              <ValueTypography>24/7</ValueTypography>
            </td>
          </tr>
        </tbody>
      </StyledTable>

      <Divider />

      <MeansOfTransportTable />

      <Divider />

      <StyledTable>
        <tbody>
          <tr>
            <td>
              <Link href="https://www.rmv.de/c/de/start/vht/">Zum Anfahrtsplan hier klicken</Link>
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </>
  );
}
