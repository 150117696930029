import { css } from '@emotion/react';
import { Button, styled } from '@mui/joy';

import { I18N_DE } from '@/i18n/de';
import { Route } from '@/routes';
import { transientOptions } from '@/utils/transient-options';

const StyledButton = styled(
  Button,
  transientOptions,
)<{ $active: boolean }>(
  ({ theme, $active }) => css`
    background-color: ${theme.palette.common.white};
    color: ${theme.palette.text.primary};
    gap: ${theme.spacing(1)};
    box-shadow: 0 3px 6px #00000029;
    border-radius: ${theme.spacing(0.5)};

    &:hover {
      background-color: ${theme.palette.primary['200']};
    }

    ${$active &&
    css`
      background-color: #d8ebff;
    `}
  `,
);

type MenuBarTopProps = {
  content: keyof typeof I18N_DE.MapLayer;
  onClick: () => void;
};

export function MenuBarTop({ content, onClick }: MenuBarTopProps) {
  const { layers } = Route.useSearch();

  return (
    <StyledButton size="lg" onClick={onClick} $active={!!layers?.includes(content)}>
      {I18N_DE.MapLayer[content]}
    </StyledButton>
  );
}
