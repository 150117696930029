import { css } from '@emotion/react';
import { Link, styled } from '@mui/joy';

export const LegalLink = styled(Link)(
  ({ theme }) => css`
    text-decoration: none;

    &:visited {
      color: ${theme.vars.palette.primary.plainColor};
    }
  `,
);
