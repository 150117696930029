import { css } from '@emotion/react';
import { Box, Stack, styled } from '@mui/joy';
import { useMemo, useState } from 'react';

import CrossIcon from '@/assets/icons/cross.svg?react';
import PuRIcon from '@/assets/icons/pur-icon.svg';
import TrainIcon from '@/assets/icons/train-icon.svg';
import { MapSidebar } from '@/components/map/sidebars/MapSidebar';
import { PurTable } from '@/components/map/stations/table/PurTable';
import { RailTable } from '@/components/map/stations/table/RailTable';
import railStationData from '@/data/bahnhof_hessen.json';
import pnrData from '@/data/pundr_hessen.json';
import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';
import { IconButtonLink } from '@/router/IconButtonLink';

const MenuHead = styled(Stack)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: ${theme.spacing(1)};
  `,
);

const StyledIconButtonLink = styled(IconButtonLink)(
  ({ theme }) => css`
    position: absolute;
    right: ${theme.spacing(2.25)};
    top: ${theme.spacing(2.25)};
    z-index: 1;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      right: ${theme.spacing(2.5)};
    }
  `,
) as typeof IconButtonLink;

const StyledStack = styled(Stack)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    background-color: ${theme.palette.neutral['400']};
    border-left: 1px solid ${theme.palette.neutral.outlinedBorder};
    padding: ${theme.spacing(2)};
    overflow: hidden;
    height: 100%;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      height: 100vh;
      overflow: auto;
    }
  `,
);

const StyledBox = styled(Box)(
  ({ theme }) => css`
    width: ${theme.spacing(5)};
    height: ${theme.spacing(0.75)};
    border-radius: 3px;
    z-index: 1;
    background-color: ${theme.palette.neutral.plainColor};
    cursor: pointer;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      display: none;
    }
  `,
);

const StyledScrollBox = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.surface};
    border-top: 1px solid ${theme.palette.neutral.outlinedActiveBg};
    flex: 1;
    overflow: auto;
    border-radius: ${theme.spacing(1)};
  `,
);

type MenuProps = {
  id?: string;
};

const DATA_SOURCES = [
  { data: pnrData, nameKey: 'pr_name', icon: PuRIcon },
  { data: railStationData, nameKey: 'rmv_name', icon: TrainIcon },
];

export function Menu({ id }: MenuProps) {
  const [mobileMenuFullscreen, setMobileMenuFullscreen] = useState(false);

  const menuInfo = useMemo(() => {
    if (!id) return undefined;

    const matchedFeature = DATA_SOURCES.flatMap(({ data, icon }) =>
      data.features.map((feature) => ({ ...feature, icon })),
    ).find(({ properties }) => properties.gml_id === id);

    if (matchedFeature) {
      return {
        icon: matchedFeature.icon,
        menuData: matchedFeature.properties,
      };
    }

    return undefined;
  }, [id]);

  return (
    <MapSidebar $mobileMenuFullscreen={mobileMenuFullscreen}>
      <StyledStack direction="column">
        <StyledScrollBox>
          <Box
            sx={{
              width: '100%',
            }}
          />
          <Box p={1}>
            <MenuHead>
              <StyledBox onClick={() => setMobileMenuFullscreen((prev) => !prev)} />
              <StyledIconButtonLink
                id="iconButtonLink"
                size="md"
                variant="plain"
                from="/"
                search={(search) => ({ ...search, menu: undefined, id: undefined })}
              >
                <CrossIcon stroke="black" />
              </StyledIconButtonLink>
            </MenuHead>
            {menuInfo?.icon === PuRIcon && <PurTable menuData={menuInfo} />}
            {menuInfo?.icon === TrainIcon && <RailTable menuData={menuInfo} />}
          </Box>
        </StyledScrollBox>
      </StyledStack>
    </MapSidebar>
  );
}
