import { css } from '@emotion/react';
import { Box, List, ListItem, Stack, styled } from '@mui/joy';
import { Link } from '@tanstack/react-router';

import HessenLogo from '@/assets/icons/hessen.svg';
import IvmLogo from '@/assets/icons/ivm.png';
import { MAIN_BREAKPOINT } from '@/modules/MAIN_BREAKPOINT';

const links = [
  {
    title: 'Häufige Fragen',
    href: '/faq',
  },
  {
    title: 'Nutzungsbedingungen',
    href: '/nutzungsbedingungen',
  },
  {
    title: 'Über diese Seite',
    href: '/ueber',
  },
  {
    title: 'Kontakt',
    href: '/kontakt',
  },
  {
    title: 'Impressum',
    href: '/impressum',
  },
];

const Root = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    flex: 1;
    padding-top: ${theme.spacing(1)};

    ul {
      display: flex;
      justify-content: flex-end;
    }

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
    }
  `,
);

const LogoWrapper = styled(Stack)(
  ({ theme }) => css`
    display: none;
    gap: ${theme.spacing(3)};

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      display: flex;
      flex-direction: row;
    }
  `,
);

const StyledLink = styled(Link)(
  ({ theme }) => css`
    font-size: ${theme.vars.fontSize.md};
    color: inherit;
    text-decoration: none;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      font-size: ${theme.vars.fontSize.sm};
    }
  `,
);

const StyledList = styled(List)(
  ({ theme }) => css`
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(1.5)};
    font-weight: 500;

    ${theme.breakpoints.up(MAIN_BREAKPOINT)} {
      flex-direction: row;
      padding: 0;
    }
  `,
);

export function Footer() {
  return (
    <Root component="footer">
      <LogoWrapper display="flex">
        <img src={HessenLogo} alt="Ivm-Logo" style={{ height: '48px' }} />
        <img src={IvmLogo} alt="Ivm-Logo" style={{ height: '48px' }} />
      </LogoWrapper>
      <StyledList role="menubar">
        {links.map(({ title, href }) => (
          <ListItem sx={{ padding: 0 }} role="none" key={title}>
            <StyledLink to={href} role="menuitem">
              {title}
            </StyledLink>
          </ListItem>
        ))}
      </StyledList>
    </Root>
  );
}
