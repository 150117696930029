import { Accordion, AccordionDetails, AccordionSummary, styled, Table } from '@mui/joy';
import { css } from '@mui/system';

import Ubahn from '@/assets/icons/ubahn.svg';
import { KeyTypography } from '@/components/map/stations/table/KeyTypography';

const StyledMeansOfTransportTable = styled(Table)(
  ({ theme }) => css`
    padding: ${theme.spacing(1)} 0;

    & tbody > tr > td:first-of-type,
    tbody > tr > td:last-child {
      padding: 8px;
      min-height: 48px;
      border: none;
    }

    p {
      padding-bottom: 20px;
    }
  `,
);

export function MeansOfTransportTable() {
  return (
    <Accordion defaultExpanded sx={{ px: 4, py: 2 }}>
      <AccordionSummary>
        <KeyTypography fontWeight={600}>Bahnlinien</KeyTypography>
      </AccordionSummary>

      <AccordionDetails>
        <StyledMeansOfTransportTable>
          <tbody>
            <tr style={{ fontWeight: 600 }}>
              <td aria-label="empty cell" style={{ width: '20%' }}>
                {' '}
              </td>
              <td style={{ border: 'none', width: '20%' }}>Linie</td>
              <td style={{ width: '60%' }}>Richtung</td>
            </tr>
            <tr>
              <td style={{ width: '10%', verticalAlign: 'middle' }}>
                <img alt="ubahn-icon" src={Ubahn} style={{ height: '30px', width: 'auto' }} />
              </td>
              <td style={{ border: 'none', width: '20%' }}>U2</td>
              <td style={{ width: '60%' }}>Bad Homburg Gonzenheim - F.-Hauptwache - F.-Südbahnhof</td>
            </tr>
            <tr>
              <td style={{ width: '10%', verticalAlign: 'middle' }}>
                <img alt="ubahn-icon" src={Ubahn} style={{ height: '30px', width: 'auto' }} />
              </td>
              <td style={{ width: '20%' }}>U3</td>
              <td style={{ width: '60%' }}>Bad Homburg Gonzenheim - F.-Hauptwache - F.-Südbahnhof</td>
            </tr>
          </tbody>
        </StyledMeansOfTransportTable>
      </AccordionDetails>
    </Accordion>
  );
}
