import { Divider, Link, Stack, Typography } from '@mui/joy';

import TrainIcon from '@/assets/icons/train-icon.svg';
import { KeyTypography } from '@/components/map/stations/table/KeyTypography';
import { MeansOfTransportTable } from '@/components/map/stations/table/MeansOfTransportTable';
import { StyledTable } from '@/components/map/stations/table/StyledTable';
import { ValueTypography } from '@/components/map/stations/table/ValueTypography';

type RailTableProps = {
  menuData: {
    icon: string;
    menuData: Record<string, any>;
  };
};

export function RailTable({ menuData: { menuData } }: RailTableProps) {
  return (
    <>
      <Stack display="flex" gap={4} alignItems="center" flexDirection="row" justifyContent="flex-start" px={4} pt={2}>
        <img alt="Bahnhof Icon" src={TrainIcon} />
        <Typography level="body-lg" fontWeight={600}>
          {menuData.rmv_name}
        </Typography>
      </Stack>
      <StyledTable>
        <tbody>
          <tr>
            <td>
              <KeyTypography fontWeight={600}>P+R Anlagen</KeyTypography>
            </td>
          </tr>

          <tr>
            <td>
              <KeyTypography>Anzahl</KeyTypography>
            </td>
            <td style={{ textAlign: 'right' }}>
              <ValueTypography>{menuData.anzahl_pr_anlagen}</ValueTypography>
            </td>
          </tr>
        </tbody>
      </StyledTable>

      {menuData.anzahl_pr_anlagen >= 1 && (
        <>
          <Divider />

          <StyledTable>
            <tbody>
              <tr>
                <td>
                  <KeyTypography fontWeight={600}>Bike + Ride</KeyTypography>
                </td>
              </tr>
              <tr>
                <td>
                  <KeyTypography>Stellplatzzahl</KeyTypography>
                </td>
                <td>
                  <ValueTypography>{menuData.pr_stellpl}</ValueTypography>
                </td>
              </tr>
              <tr>
                <td>
                  <KeyTypography>davon überdacht</KeyTypography>
                </td>
                <td>
                  <ValueTypography>{menuData.br_stell_dach}</ValueTypography>
                </td>
              </tr>
            </tbody>
          </StyledTable>
        </>
      )}

      <Divider />

      <MeansOfTransportTable />

      <Divider />

      <StyledTable>
        <tbody>
          <tr>
            <td>
              <Link href="https://www.rmv.de/c/de/start/vht/">Zum Anfahrtsplan hier klicken</Link>
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </>
  );
}
