import { IconButton, IconButtonProps } from '@mui/joy';
import { createLink } from '@tanstack/react-router';
import { forwardRef } from 'react';

type IconButtonLinkProps = Omit<IconButtonProps<'a'>, 'component'>;

const UnwrappedIconButtonLink = forwardRef<HTMLAnchorElement, IconButtonLinkProps>(
  (props: IconButtonLinkProps, ref) => <IconButton ref={ref} component="a" {...props} />,
);

export const IconButtonLink = createLink(UnwrappedIconButtonLink);
